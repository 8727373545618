
import * as Components from "./Kluckinall"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "kluckinall"
}

