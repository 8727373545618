import {sortBy} from 'lodash'
import calcItemPriceArray from '../react/calcItemPriceArray'
export default function calcPromoDiscount (args) {
  // console.log({shopId, products})
  try {
    const {
      shopId, cartType,
      products, promoCode='', totalPrice, taxTotal
    } = args

    let totalDiscount = 0


    if (shopId === 'lancers') {
      // if (['7SH9SAG8', '7sh9sag8'].indexOf((promoCode || '').toLowerCase()) !== -1) {
      //   totalDiscount += Math.min(100, (totalPrice+taxTotal))
      // }
      // if (['AX89FHS3HO', 'ax89fhs3ho'].indexOf((promoCode || '').toLowerCase()) !== -1) {
      //   totalDiscount += Math.min(200, (totalPrice+taxTotal))
      // }
    }
    if (shopId === 'pablitostacosburbank') {
      // if (['rainyday'].indexOf((promoCode || '').toLowerCase()) !== -1) {
      //   totalDiscount += Math.round(totalPrice * 0.2 * 100) / 100
      // }
      
    }
    if (shopId === 'kokorollpa') {
      if (['sarahcaltech'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
      }
      
    }
    if (shopId === 'pablitosworld') {
      const priceList = []
      products.forEach(({tags, sub, price, quantity}) => {
        console.log(tags)
        if (tags && tags.indexOf('bogo-promo') !== -1) { // is bogo
          for (let step = 0; step < quantity; step++) {
            priceList.push(Number(price))
          }
        }
       
      })
      const sorted = sortBy(priceList)
      if (sorted.length > 1) {
        totalDiscount += sorted[0]
      }
      
    }
    // if (shopId === 'ksthollywood') {
    //   const priceList = []
    //   products.forEach(({name, tags, sub, price, quantity}) => {
    //     console.log(tags)
    //     if (name && name.en.indexOf('Poke Bowl') !== -1) { // is bogo
    //       for (let step = 0; step < quantity; step++) {
    //         priceList.push(Number(price))
    //       }
    //     }
       
    //   })
    //   const sorted = sortBy(priceList)
    //   console.log('kst price list:', sorted)
    //   if (sorted.length > 0) {
    //     const discountCount = Math.floor(sorted.length / 2)
    //     console.log({discountCount})
    //     const pricesToDiscount = sorted.slice(0,discountCount)
    //     console.log('prices', pricesToDiscount)
    //     pricesToDiscount.forEach((price) => {
    //       totalDiscount += (price / 2)
    //     })
    //   }
      

      
    // }


  
    if (shopId === 'kluckinsd') {
      const now = new Date()
      const day = now.getDay() // Sunday - Saturday : 0 - 6
      const hours = now.getHours() //0 to 23
      const minutes = now.getMinutes() // 0 to 59
      console.log(promoCode, day, hours)
      if (['kluckin15'].indexOf((promoCode || '').toLowerCase()) !== -1) {
       
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
       
      }
      if (['getklucked'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        if ([0,1,21,22,23].indexOf(hours) !== -1) {
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        }
      }
      if (['kluckinlunch'].indexOf((promoCode || '').toLowerCase()) !== -1) {
        if ([12,13].indexOf(hours) !== -1) {
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        }
        if ((hours === 11) && (minutes > 29)) {
          totalDiscount += Math.round(totalPrice * 0.15 * 100) / 100
        }
      }
    }

    if (shopId === 'kokorollpa') {
      if (['b2spcc'].indexOf(
        (promoCode || '').toLowerCase()) !== -1
      ) {
          
        let qualifyingItemPrice = false
        products.forEach((product) => {
          const {tags, sub, price, quantity, } = product
          console.log(product)
          if (tags && tags.indexOf('buy-10-get-free-drink') !== -1) { // is bogo
            qualifyingItemPrice = Number(price)
          }
          
        })
        console.log('haha',qualifyingItemPrice)
        if (qualifyingItemPrice && (totalPrice > (9.9 + qualifyingItemPrice))) {
          totalDiscount += qualifyingItemPrice
        }
   
    }
    }
    if (shopId === 'pablitostacosnorth') {
      let hasCafe = false
      let hasBurger = false
      let promoTotal = 0
      
      products.forEach(({name, sub, price, quantity=1}) => {
        console.log(name, sub)
        if (name && name.en.indexOf('$5 Pizza') !== -1) { // is street tacos
          hasCafe = true
          promoTotal+= 13
        } 
        if (name && name.en.indexOf('$5 la clásica smashed cheeseburger') !== -1) { // is street tacos
          hasBurger = true
          promoTotal+= 13
        } 
      })

      if ((totalPrice - promoTotal) > 10) {

        if (hasCafe) {
          totalDiscount += 8
        }
        if (hasBurger) {
          totalDiscount += 8
        }
      }
    }

    if (shopId === 'verdugopizza') {
      if (['armo24'].indexOf(
        (promoCode || '').toLowerCase()) !== -1
      ) {
        const priceList = []
        products.forEach((product) => {
          const {tags, sub, price, quantity=1} = product
          console.log(tags)
          if (tags && tags.indexOf('bogo-promo') !== -1) { // is bogo
            
            for (let step = 0; step < quantity; step++) {
              const calculatedPrice = calcItemPriceArray(product)
              priceList.push(Number(calculatedPrice.totalPrice))
            }
          }
         
        })
        const sorted = sortBy(priceList)
        console.log('sorted', sorted)
        if (sorted.length > 1) {
          totalDiscount += Math.round(sorted[0] / 2)
        }
      }
    }

    if (shopId === 'kluckinoaks') {
      // for any product with tag 'bogo' if there are more than 1 of the same product, discount the lowest price, taking into account quantity
      // can give discounts to any number of products
      const priceDict = {}
      products.forEach(({tags, sub, productId, price, quantity=1}) => {
        if (tags && tags.indexOf('bogo') !== -1) { // is bogo
          for (let step = 0; step < quantity; step++) {
            const priceList = priceDict[productId] || []
            priceList.push(Number(price))
            priceDict[productId] = priceList
          }
        }
      })
      Object.keys(priceDict).forEach((productId) => {
        const priceList = priceDict[productId]
        const sorted = sortBy(priceList)
        if (sorted.length > 1) {
          totalDiscount += sorted[0]
        }

      })

      


    }




    // if (shopId === 'pablitostacosburbank') {
    //   if (['janequengua3'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.min(9, (totalPrice + taxTotal))
    //   }
    // // }
   
    // if (shopId === 'sushidon') {
    //   // cinco
    //   let numTrays = 0
    //   products.forEach(({name, sub, price, quantity=1}) => {
    //     console.log(name, sub)
    //     if (name && name.en === 'Rainbow Tray ') { // is street tacos
    //       numTrays += quantity
    //     } 
    //     if (name && name.en === ('Rock & Roll  platter')) { // is street tacos
    //       numTrays += quantity
    //     } 
    //   })
    //   console.log('numTrays', numTrays)

    //   if (numTrays === 1) {
    //     totalDiscount = totalDiscount + Math.round(40 * 0.05 * 100) / 100
    //   }
    //   if (numTrays === 2) {
    //     totalDiscount = totalDiscount + Math.round(80 * 0.10 * 100) / 100
    //   }
    //   if (numTrays > 2) {
    //     totalDiscount = totalDiscount + Math.round(40 * numTrays * 0.15 * 100) / 100
    //   }
      
      
      
    // }
    if (shopId === 'pablitostacosburbank') {
      let hasCafe = false
      let hasBurger = false
      let hasWings = false
      let promoTotal = 0
      
      products.forEach(({name, sub, price, quantity=1}) => {
        console.log(name, sub)
        if (name && name.en.indexOf('$5 Pizza') !== -1) { // is street tacos
          hasCafe = true
          promoTotal+= 13
        } 
        if (name && name.en.indexOf('$5 la clásica smashed cheeseburger') !== -1) { // is street tacos
          hasBurger = true
          promoTotal+= 13
        } 
        if (name && name.en.indexOf('$1 Wings') !== -1) { // is street tacos
          hasWings = true
          promoTotal+= 10
        } 
      })

      if ((totalPrice - promoTotal) > 10) {
        console.log('!!!',{totalPrice, promoTotal})

        if (hasCafe) {
          totalDiscount += 8
        }
        if (hasBurger) {
          totalDiscount += 8
        }
        if (hasWings) {
          totalDiscount += 10
        }
      }
            
    }
 
    if (shopId === 'pablitostacosnoho') {
      let hasCafe = false
      let hasBurger = false
      let hasWings = false
      let promoTotal = 0
      
      products.forEach(({name, sub, price, quantity=1}) => {
        console.log(name, sub)
        if (name && name.en.indexOf('$5 Pizza') !== -1) { // is street tacos
          hasCafe = true
          promoTotal+= 13
        } 
        if (name && name.en.indexOf('$5 la clásica smashed cheeseburger') !== -1) { // is street tacos
          hasBurger = true
          promoTotal+= 13
        } 
        if (name && name.en.indexOf('$1 Wings') !== -1) { // is street tacos
          hasWings = true
          promoTotal+= 10
        } 
      })

      if ((totalPrice - promoTotal) >= 10) {
        console.log('!!!',{totalPrice, promoTotal})

        if (hasCafe) {
          totalDiscount += 8
        }
        if (hasBurger) {
          totalDiscount += 8
        }
        if (hasWings) {
          totalDiscount += 10
        }
      }
            
    }
    // if (shopId === 'cravesunset') {
    //   if (['ida40'].indexOf((promoCode || '').toLowerCase()) !== -1) {
    //     totalDiscount += Math.round(totalPrice * 0.1 * 100) / 100
    //   }
    // }
   
    // if (shopId === 'muiishi') {
    //   let hasChicken = false
    //   let totalPrice = 0
    //   products.forEach(({name, sub, price, quantity=1}) => {
    //     if (name && name.en.indexOf('Gyoza') !== -1) { // is wine
    //       hasChicken = true
    //     } else {
    //       totalPrice = price * quantity + totalPrice
    //     }
    //   })
      

    //   if ((totalPrice > 10) && (hasChicken)) {
    //     totalDiscount += Number(5.99)
    //   }

    // }


    return Math.round(totalDiscount * 100) / 100
  } catch (e) {
    return 0
  }


}
