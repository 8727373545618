import React from "react";
import {Helmet} from "react-helmet";
// import {injectShopContainer} from '../../react/ShopDataContainer'


export default class Base extends React.Component {
  render () {
    // console.log('hi', shop)
    let name = "Kluckin Chicken"
    let description = "Los Angeles Nashville-style fried chicken."
    return (
      <Helmet>
          <meta charSet="utf-8" />
          <title>{name}</title>
          <meta name="description"
            content={description} />
          <link rel="canonical" href="https://thekluckinchicken.com" />

          <link rel="manifest" href={`${process.env.PUBLIC_URL}/kluckinall.json`} />
          <link rel="apple-touch-icon" sizes="250x250"
            href={`${process.env.PUBLIC_URL}/kluckin.png`} />
          <link rel="apple-touch-icon" sizes="250x250"
            href={`${process.env.PUBLIC_URL}/kluckin.png`} />
          <link rel="shortcut icon"
            href={`${process.env.PUBLIC_URL}/kluckin.png`}
          />

          {/* Open Graph */}
          <meta property="og:title" content={name} />
          <meta property="og:site_name" content={name} />
          <meta property="og:description" content={description} />
          {/* <meta property="og:image" content={} /> */}
          {/* <meta property="og:url" content="http://euro-travel-example.com/index.htm" /> */}
      </Helmet>


    );
  }
};

// export default injectShopContainer(Base)
